<template>
  <default-layout>
    <div class="container">
      <static-page />
    </div>
  </default-layout>
</template>

<script>
const StaticPage = () => import('@/components/static-page/privacy-policy');
import DefaultLayout from '@/layouts/default';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    StaticPage,
    DefaultLayout,
  },
};
</script>
